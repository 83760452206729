/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 17, 2019 */

@font-face {
  font-family: 'HK Compakt';
  src: url('hkcompakt-regular.woff2') format('woff2'), url('hkcompakt-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HK Compakt';
  src: url('hkcompakt-bold.woff2') format('woff2'), url('hkcompakt-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HK Compakt';
  src: url('hkcompakt-medium.woff2') format('woff2'), url('hkcompakt-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
