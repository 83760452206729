/*
Font: 		Gimbo
Style: 		Ultra Shadow
URL: 		https://www.youworkforthem.com/font/T12585/gimbo
Foundry: 	Letter Omega
Foundry: 	https://www.youworkforthem.com/designer/1077/letter-omega
Copyright:	Copyright © 2020 by Doni Sukma. All rights reserved.
Version: 	17
Created:	July 14, 2020
License: 	https://www.youworkforthem.com/font-license 
License: 	The WebFont(s) listed in this document must follow the YouWorkForThem
			WebFont license rules. All other parties are strictly restricted 
			from using the WebFonts(s) listed without a purchased license. 
			All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
	font-family: 'Gimbo Ultra Shadow';
	src: url('gimbo-ultra-shadow.eot');
	src: url('gimbo-ultra-shadow.eot?#iefix') format('embedded-opentype'),
             url('gimbo-ultra-shadow.woff2') format('woff2'),
	     url('gimbo-ultra-shadow.woff') format('woff'),
	     url('gimbo-ultra-shadow.ttf') format('truetype'),
	     url('gimbo-ultra-shadow.svg#youworkforthem') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
