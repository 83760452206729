@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: normal;
  src: url('./GT-America-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT America';
  font-style: normal;
  font-weight: bold;
  src: url('./GT-America-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT America Mono';
  font-style: normal;
  font-weight: normal;
  src: url('./GT-America-Mono.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT America Extended';
  font-style: normal;
  font-weight: normal;
  src: url('./GT-America-Extended-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT America Expanded';
  font-style: normal;
  font-weight: 'medium';
  src: url('./GT-America-Expanded-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'GT America Expanded';
  font-style: normal;
  font-weight: 900;
  src: url('./GT-America-Expanded-Black.woff') format('woff');
  font-display: swap;
}
